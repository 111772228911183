<template>
  <!-- Registeration success or failed component -->
  <div class="page-section">
    <div class="container">
      <b-skeleton-wrapper :loading="isLoading">
        <template #loading>
          <div class="page-section d-flex justify-content-center">
            <div class="card card-block card-stretch card-height col-md-8">
              <div class="card-body text-center">
                <div class="d-flex justify-content-center my-4">
                  <b-skeleton type="avatar" style="font-size: 1.1rem"></b-skeleton>
                </div>
                <div class="d-flex justify-content-center">
                  <b-skeleton width="50%" class="mb-4"></b-skeleton>
                </div>
                <p class="lead text-white-50">
                  <b-skeleton></b-skeleton>
                </p>
                <p class="lead text-white-50">
                  <b-skeleton></b-skeleton>
                </p>
                <p class="lead text-white-50">
                  <b-skeleton width="75%"></b-skeleton>
                </p>
                <div class="d-flex justify-content-center">
                  <b-skeleton type="button" width="160px"></b-skeleton>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="page-section d-flex justify-content-center">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i v-if="isSubmit" class="fas fa-check-circle fa-lg text-success mt-4 mb-4" style="font-size: 3rem" />
              <i v-else class="fas fa-times-circle fa-lg text-danger mt-4 mb-4" style="font-size: 3rem" />
              <h4>{{ isSubmit ? 'Successfully Submitted' : 'Submission Failed!' }}</h4>

              <p class="text-muted" v-if="isSubmit">
                {{
                  `You have successfully submitted your application for ${sponsorship}. We will get back to you soon.`
                }}
              </p>
              <p class="text-muted" v-else>
                Your installment cannot be paid. Please try again later or contact the administrator.
              </p>
              <router-link to="/student/sponsorships" exact class="btn btn-primary"
                ><i class="fas fa-home mr-2"></i>Back to Sponsorships
              </router-link>
            </div>
          </div>
        </div>
      </b-skeleton-wrapper>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlanPaymentSuccess',

  props: {
    isSubmit: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: true },
    sponsorship: { type: String, default: '' },
  },
  data() {
    return {};
  },

  async mounted() {},
};
</script>

<style>
</style>